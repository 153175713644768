
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "settingLogo",
  props: {},

  created() {
    const loggedin = localStorage.getItem("userId");
    base
      .getUser(loggedin)
      .then((res) => {
        this.userData = res.data;
        this.logoValue();
        this.logoSrc = "/companies/logo_image/" + this.userData.cmp.id;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
  },

  data() {
    return {
      fileList: [],
      userData: { cmp: {} },
      newImage: [],
      baseLogo: [],
      checkLogo: "",
    };
  },

  methods: {
    logoValue() {
      if (this.userData.cmp.default_logo_use_yn == false) {
        this.checkLogo = false;
      } else {
        this.checkLogo = true;
      }
    },

    select() {
      this.checkLogo = "false";
    },

    uploadImage(e) {
      {
        this.newImage = e.target.files[0];
        console.log(this.newImage);
      }
    },

    async submit() {
      const id = this.userData.cmp.id;
      if (this.checkLogo === "false") {
        const formData = new FormData();
        formData.append("cmp_logo", this.newImage);
        formData.append("default_logo_use_yn", "false");
        console.log(formData);
        const pairs = Array.from(formData.entries());
        for (const pair of pairs) {
          console.log(pair);
        }
        await base
          .updateCompanyFile(formData, id)
          .then((res) => {
            if (res.status == 200) {
              alert("회사 로고를 설정했습니다.");
              console.log(res);
              window.location.reload();
            } else {
              alert(res.status);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);
            const value = Object.values(err.response.data);
            alert(value);
          });
      } else {
        const formData = new FormData();
        formData.append("default_logo_use_yn", "true");
        console.log(formData);

        await base
          .updateCompanyFile(formData, id)
          .then((res) => {
            if (res.status == 200) {
              alert("회사 로고를 설정했습니다.");
              console.log(res);
              window.location.reload();
            } else {
              alert(res.status);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);
            const value = Object.values(err.response.data);
            alert(value);
          });
      }
    },
  },

  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1",
    });

    return {
      data,
    };
  },
});
